
import RenderInstructionService from "@/services/RenderInstructionService"
import { ref, reactive, onMounted } from "vue"
import SecondaryButton from "@/components/buttons/SecondaryButton.vue"
import RenderInstructionImport from "@/components/settings/render-instructions/RenderInstructionImport.vue"

export default {
  components: { SecondaryButton, RenderInstructionImport },
  props: {
    organization: {
      type: Object,
      default: null,
    },
    openImport: {
      type: Boolean,
      default: false,
    },
    orgIdToSelect: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
  },
  setup(props: any) {
    const service = new RenderInstructionService("riannotations")
    const additionalFields = reactive({ annotations: "" })
    const columns = reactive([{ field: "annotations", header: "Annotations", width: "11rem" }])
    const collection = ref()

    onMounted(() => {
      collection.value.riImportDialog = props.openImport
    })

    function closeDialogs() {
      collection.value.addDialog = false
      collection.value.editDialog = false
      collection.value.duplicateDialog = false
      collection.value.riImportDialog = false
      collection.value.reload()
    }

    function showRiImportDialog() {
      collection.value.riImportDialog = true
    }

    return {
      service,
      additionalFields,
      columns,
      collection,
      closeDialogs,
      showRiImportDialog,
    }
  },
}
